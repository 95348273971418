import * as React from 'react';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    FilterLiveSearch,
    SearchInput,
    Form,
    SelectInput,
    required,
    useRefresh,
    SimpleList,
    Loading,
    FunctionField
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
    useMediaQuery
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import { stages } from './stages';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { OnlyMineInput } from '../requests/OnlyMineInput';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: deals,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

    const handleClick = () => {

        const fieldOrder = [
            'id',
            'name',
            'description',
            'address',
            'cadastre',
            'size',
        ];

        const reorderedDataToExport = deals.map((account) => {
            const reorderedAccount = {};
            fieldOrder.forEach((field) => {
                reorderedAccount[field] = Array.isArray(account[field]) ? account[field].join(', ') : account[field];
            });
            return reorderedAccount;
        });

        const ws = XLSX.utils.json_to_sheet(reorderedDataToExport);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
        XLSX.writeFile(wb, "Сделки - Выгрузка из CRM.xlsx");
    };

    return (
        <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
            Выгрузить в Excel
        </Button>
    );
};

const DealListContent = forwardRef((props, ref) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { setSort } = useListContext();
    const setSort1 = (field1, order1) => {
        setSort({ field: field1, order: order1 });
    };

    // Expose the setSort function using useImperativeHandle
    useImperativeHandle(ref, () => ({
        setSort1: (field1, order1) => setSort1(field1, order1),
    }));

    const {
        data: deals,
        isLoading, isFetching,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading || isFetching) {
        return <Loading />
    }
    const now = Date.now();

    const getStatusColor = (stageId) => {
        switch (stageId) {
            case 'sale':
                return 'green'; // Green circle for 'sale'
            case 'reserved':
                return 'yellow';
            case 'reserved_partly_paid':
                return 'yellow'; 
            case 'reserved_fully_paid':
                return 'yellow'; 
            case 'dealing':
                return '#C133FF'; 
            case 'documents_done':
                return '#C133FF'; 
            case 'sold':
                return 'red'; // Red circle for 'sold'
            default:
                return 'grey'; // Default color for unknown status
        }
    };

    return (
        <>
            {isSmall ?
                <SimpleList
                    primaryText={deal => <Box display="flex" justifyContent="space-between">Сделка №{deal.document_name} <FiberManualRecordIcon style={{ color: getStatusColor(deal.stage_id) }}/></Box>}
                    secondaryText={deal => `${new Date(deal.created_at).toLocaleString('ru-RU')}, ${deal.name} - ${deal.manager_name}`}
                    linkType={"edit"}
                /> :
                <List>
                    {deals.map(deal => (
                        <RecordContextProvider key={deal.id} value={deal}>
                            <ListItem
                                button
                                component={Link}
                                to={`/deals/${deal.id}`}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem' }}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon style={{ color: getStatusColor(deal.stage_id) }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={deal.document_name ? `Сделка №${deal.document_name}` : `Сделка №${deal.number}`}
                                        secondary={deal.updated_at ? `Обновлена ${new Date(deal.updated_at).toLocaleString('ru-RU')}` : `Создана ${new Date(deal.created_at).toLocaleString('ru-RU')}`}
                                    />
                                </Box>
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem' }}>
                                    <ListItemText
                                        primary={`Клиент: ${deal.name}`}
                                        secondary={`Менеджер: ${deal.manager_name}`}
                                    />
                                </Box>
                                <Box display="flex" flex={2} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem' }}>
                                    <ListItemText
                                        primary={deal.village_id ? <ReferenceField source="village_id" reference="villages" link="show">
                                            <TextField source="name" />
                                        </ReferenceField> :
                                            <ReferenceField source="request_id" reference="requests">
                                            <FunctionField
                                                render={record => 
                                                    record?.realty_id 
                                                        ? `Объект: ${record.realty_title}` 
                                                        : "Объект не присвоен"
                                                }
                                            />
                                            </ReferenceField>
                                        }
                                        secondary={`Стоимость сделки: ${deal.deal_price} руб.`}
                                    />
                                </Box>
                            </ListItem>
                        </RecordContextProvider>
                    ))}
                </List>}
        </>
    );
});

const DealListActions = ({ filters, currentFilter, handleFilterChange, stageFilter, handleStageFilterChange, isSmall }) => (
    <TopToolbar sx={{ display: 'flex', flexDirection: isSmall ? 'column' : 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Form className='tasks-views' sx={{ marginLeft: 2 }}>
            <SelectInput
                source="stage_id"
                label="Этап сделки"
                choices={stages.slice(1)}
                onChange={handleStageFilterChange} // Handle stage filter change
                alwaysOn
                helperText={null}
                value={stageFilter}
                sx={{ minWidth: '14rem' }}
            />
        </Form>
        {!isSmall && <Form className='filterForm'>
            <SelectInput label="Отображение" source="view" choices={filters} defaultValue={currentFilter.id} onChange={handleFilterChange} validate={required()} helperText={null} />
        </Form>}
        {localStorage.getItem('role_id') < 2 && !isSmall ? <CustomExportButton /> : null}
        <CreateButton
            variant="outlined"
            label="Новая сделка"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const DealList = () => {
    const { identity } = useGetIdentity();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const filters = [
        { id: 1, name: 'По дате (от новых к старым)', order: 'DESC', field: 'updated_at' },
        { id: 2, name: 'По дате (от старых к новым)', order: 'ASC', field: 'updated_at' },
    ];
    const [currentFilter, setCurrentFilter] = useState(filters[0]);
    const [stageFilter, setStageFilter] = useState('');
    const dealListContentRef = useRef();

    const handleFilterChange = (value) => {
        setCurrentFilter(filters.find((item) => item.id === value.target.value));
        if (dealListContentRef.current) {
            dealListContentRef.current.setSort1(filters.find((item) => item.id === value.target.value).field, filters.find((item) => item.id === value.target.value).order);
        }
    };

    const handleStageFilterChange = (e) => {
        setStageFilter(e.target.value);
    };

    return identity ? (
        <RaList
            sx={{ marginTop: '1rem' }}
            actions={<DealListActions filters={filters} currentFilter={currentFilter} handleFilterChange={handleFilterChange} stageFilter={stageFilter} handleStageFilterChange={handleStageFilterChange}  isSmall={isSmall}/>}
            perPage={50}
            empty={false}
            sort={{ field: 'updated_at', order: 'DESC' }}
            filter={{ 'sales_id_for_requests': localStorage.getItem('id'), ...(stageFilter && { stage_id: stageFilter }) }}
            filters={localStorage.getItem('role_id') <= 3 ? requestsFilters : requestsFiltersForManagers}
        >
            <div style={{ marginBottom: '2rem' }}>
                <div style={{ position: 'absolute', top: 0, left: 0 }}>
                    <Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />
                </div>
            </div>
            <DealListContent ref={dealListContentRef} />
        </RaList>
    ) : null;
};

const requestsFilters = [
    <SearchInput source="q" alwaysOn />,
    <OnlyMineInput alwaysOn />,
];
const requestsFiltersForManagers = [
    <SearchInput source="q" alwaysOn />,
];

export default DealList;
